<template>
  <b-card class="no-shadow p-0">
    <div class="pt-2 dataTables_wrapper VueTables no-shadow table-responsive">
      <b-row class="p-3">
        <b-col lg="4" sm="12">
          <b-form-select v-model="filter_brand">
            <b-form-select-option value="null">Select brand</b-form-select-option>
            <b-form-select-option v-for="(item, index) in brands" :value="item.make" :key="index">{{
                item.make
              }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="4" sm="12">
          <b-form-select v-model="filter_model">
            <b-form-select-option value="null">Select model</b-form-select-option>
            <b-form-select-option v-for="(item, index) in models" :value="item.model" :key="index">{{
                item.model
              }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="4" sm="12">
          <b-form-select v-model="filter_generation">
            <b-form-select-option value="null">Select generacion</b-form-select-option>
            <b-form-select-option v-for="(item, index) in generations" :value="item.generation" :key="index">
              {{ item.generation }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="4" sm="12">
          <b-form-select v-model="filter_engine">
            <b-form-select-option value="null">Select engine</b-form-select-option>
            <b-form-select-option v-for="(item, index) in engines" :value="item.engine" :key="index">
              {{ item.engine }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="4" sm="12">
          <b-form-select :options="country_options" v-model="country_id">
            <b-form-select-option :value="null">Select country</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="4" sm="12">
          <b-button class="btn btn-danger btn-block" @click="deleteByCountry">Delete by country</b-button>
        </b-col>
      </b-row>
      <b-table small bordered hover class="dataTable" :items="table.tableData" :fields="fields">
        <template #cell(stageOnePrice)="{item}" class="p-0">
          <span v-html="formatObjectCell(item.stageOnePrice)"></span>
        </template>
        <template #cell(stageTwoPrice)="{item}">
          <span v-html="formatObjectCell(item.stageTwoPrice)"></span>
        </template>
        <template #cell(stageTwoPlusPrice)="{item}">
          <span v-html="formatObjectCell(item.stageTwoPlusPrice)"></span>
        </template>
        <template #cell(stageThreePrice)="{item}">
          <span v-html="formatObjectCell(item.stageThreePrice)"></span>
        </template>
        <template #cell(stageFourPrice)="{item}">
          <span v-html="formatObjectCell(item.stageFourPrice)"></span>
        </template>
        <template #cell(to_charge)="{item}">
          <b-checkbox class="mx-auto checkbox-center" :checked="item.to_charge" disabled/>
        </template>
        <template #cell(to_show)="{item}">
          <b-checkbox class="mx-auto checkbox-center" :checked="item.to_show" disabled/>
        </template>
        <template #cell(options)="{item}">
          <b-button size="sm" variant="danger" title="Delete" @click="deleteItem(item._id)">
            <i class="material-icons">delete</i>
          </b-button>
          <b-button size="sm" variant="dark" title="View" @click="$refs.modal.open(item, item.id)">
            <i class="material-icons">compare_arrows</i>
          </b-button>
        </template>
      </b-table>
    </div>
    <div class="d-flex justify-content-between">
      <p class="justify-content-start pl-4 pt-3">
        <span v-show="table.total">
          Showing {{ table.from }} to {{ table.to }} of
        {{ table.total }} records
        </span>
      </p>
      <b-pagination
        class="justify-content-end no-shadow p-2"
        :value="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
        @change="paginate"
      />
    </div>
    <modal-comparator ref="modal" url="vehicles-price-config/vehicle-to-compare/" :fields="fields_"/>
  </b-card>
</template>

<script>
import {Pagination} from "@/utils/pagination";
import {get, post} from "@/services/api";
import {mapState} from "vuex";
import {serialize} from "@/utils";
import ModalComparator from "@/views/dashboard/vehicles-price-config/components/ModalComparator";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "ECUList",
  mixins: [show_alert_mixin],
  components: {ModalComparator},
  props: {
    country_options: {
      default: [],
      type: Array
    }
  },
  data: () => ({
    fields: [
      'id',
      'brand',
      'model',
      'generation',
      'engine',
      {key: 'stageOnePrice', label: 'Stage I', tdClass: 'p-0'},
      {key: 'stageTwoPrice', label: 'Stage II', tdClass: 'p-0'},
      {key: 'stageTwoPlusPrice', label: 'Stage II+', tdClass: 'p-0'},
      {key: 'stageThreePrice', label: 'Stage III', tdClass: 'p-0'},
      {key: 'stageFourPrice', label: 'Stage IV', tdClass: 'p-0'},
      {key: 'country.name', label: 'Country'},
      {key: 'distributor.username', label: 'Distributor'},
      {key: 'to_charge', label: 'To Charge'},
      {key: 'to_show', label: 'To Show'},
      'options'
    ],
    fields_: [
      'id',
      'brand',
      'model',
      'generation',
      'engine',
      {key: 'stageOnePrice', label: 'Stage I', tdClass: 'p-0'},
      {key: 'stageTwoPrice', label: 'Stage II', tdClass: 'p-0'},
      {key: 'stageTwoPlusPrice', label: 'Stage II+', tdClass: 'p-0'},
      {key: 'stageThreePrice', label: 'Stage III', tdClass: 'p-0'},
      {key: 'stageFourPrice', label: 'Stage IV', tdClass: 'p-0'},
    ],
    table: new Pagination(),
    query: '',
    country_id: null
  }),
  computed: {
    ...mapState('orderSoftware', ['brands', 'models', 'engines', 'generations', 'filters', 'arrayGearbox', 'filters']),
    filter_brand: {
      get() {
        return this.$store.state.orderSoftware.filters.brand;
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'brand',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'models');
        this.load()
      }
    },
    filter_model: {
      get() {
        return this.$store.state.orderSoftware.filters.model;
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'model',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'generations');
        this.load()
      }
    },
    filter_generation: {
      get() {
        return this.$store.state.orderSoftware.filters.generation;
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'generation',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'engines');
        this.load()
      }
    },
    filter_engine: {
      get() {
        return this.$store.state.orderSoftware.filters.engine;
      },
      set(value) {
        if (this.engines.length && this.engines[0].generation) {
          this.$store.state.orderSoftware.filters.generation = this.engines.find(el => el.engine == value).generation;
        }
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'engine',
          value
        });
        this.$store.commit('orderSoftware/clearGearbox')
        this.load()
      },
    },
  },
  methods: {
    load() {
      this.$store.commit('loaderManager', true);
      const extra_filter = {
        vehicles: this.filters,
        country_id: this.country_id
      }
      get(`vehicles-price-config/ecu-list?page=${this.table.currentPage}&limit=${this.table.perPage}${serialize(extra_filter, '&')}`)
        .then(({data}) => {
          this.table.total = data.count;
          this.table.setTableData(data.data);
        }).finally(() => this.$store.commit('loaderManager', false))
    },
    paginate(page) {
      this.table.currentPage = page;
      this.load();
    },
    formatObjectCell(data) {
      if (!!data && Object.keys(data).length) {
        const val = []
        Object.keys(data).forEach(k => {
          val.push(`${k} = ${data[k]}`)
        })
        return val.join('<br>')
      }
      return data;
    },
    deleteItem(_id) {
      this.$swal({
        title: 'Are you sure?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(`vehicles-price-config/ecu-delete/${_id}`, null, true)
            .then(() => {
              this.load();
              this.showAlertNotification('Deleted successfully')
            })
        }
      });
    },
    deleteByCountry() {
      const inputOptions = {}
      this.country_options.forEach(el => {
        inputOptions[el.value] = el.text;
      });
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'question',
        title: 'Select a country',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Confirm and delete",
        cancelButtonText: "Cancel",
        input: "select",
        inputOptions: inputOptions,
      }).then(async (value) => {
        if (value.isConfirmed) {
          this.deleteItem(value.value)
        }
      })
    }
  },
  mounted() {
    this.$store.dispatch('orderSoftware/loadBrands');
    this.load()
  },
  watch: {
    country_id() {
      this.load()
    },
  },
  destroyed() {
    this.$store.commit('orderSoftware/clear')
  }
}
</script>

<style scoped>

</style>
