<template>
  <b-card class="no-shadow p-0">
    <div class="pt-2 dataTables_wrapper VueTables no-shadow table-responsive">
      <b-table small bordered hover class="dataTable" :items="table.tableData" :fields="fields">
        <template #cell(minimum_price)="{item}">
          <currency-price-view :amount="item.minimum_price"/>
        </template>
        <template #cell(percentage_on_request)="{item}">
          {{ ((item.percentage_on_request - 1) * 100).toFixed() }}%
        </template>
        <template #cell(options)="{item}">
          <div class="d-flex justify-content-between">
            <div>
              <b-button size="sm" @click="edit(item)">
                <i class="material-icons">edit</i>
              </b-button>
              <b-button variant="danger" size="sm" @click="remove(item)">
                <i class="material-icons">delete</i>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <div class="d-flex justify-content-between">
      <p class="justify-content-start pl-4 pt-3">
        <span v-show="table.total">
          Showing {{ table.from }} to {{ table.to }} of
        {{ table.total }} records
        </span>
      </p>
      <b-pagination
        class="justify-content-end no-shadow p-2"
        :value="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
        @change="paginate"
      />
    </div>
    <edit-price-policy ref="editPricePolicy"/>
  </b-card>
</template>

<script>
import {Pagination} from "@/utils/pagination";
import {deleted, get} from "@/services/api";
import {serialize} from "@/utils";
import CreditPrice from "@/views/dashboard/credit-price";
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import EditPricePolicy from "@/views/dashboard/vehicles-price-config/components/EditPricePolicy";

export default {
  name: "PricePolicies",
  components: {EditPricePolicy, CurrencyPriceView, CreditPrice},
  data: () => ({
    fields: [
      {key: 'country.name', label: 'Country'},
      {key: 'distributor.username', label: 'Distributor'},
      {key: 'minimum_price', label: 'Minimun price'},
      {key: 'percentage_on_request', label: 'Percentage on request'},
      {key: 'options', label: 'Options'},
    ],
    table: new Pagination()
  }),
  methods: {
    load() {
      this.$store.commit('loaderManager', true);
      get(`vehicles-price-config/country-policies?page=${this.table.currentPage}&limit=${this.table.perPage}${serialize(this.filter, '&')}`)
        .then(({data}) => {
          this.table.total = data.count;
          this.table.setTableData(data.data);
        }).finally(() => this.$store.commit('loaderManager', false))
    },
    remove(item) {
      this.$swal({
        title: 'Are you sure to delete this price policy?',
        text: "This action will delete all prices modified by this Distributor as well.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes, delete it!'
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          this.$store.commit('loaderManager', true);
          deleted(`vehicles-price-config/price-policy/${item._id}`, true)
            .then(() => {
              this.$swal({
                title: 'Success',
                text: 'Price policy and all modified prices were successfully deleted',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#d14343',
              }).then(() => {
                this.load();
                this.$emit('reload');
              })
            }).finally(() => this.$store.commit('loaderManager', false))
        }
      })
    },
    paginate(page) {
      this.table.currentPage = page;
      this.load();
    },
    edit(item) {
      this.$refs.editPricePolicy.open(item);
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped>

</style>
