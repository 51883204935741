import Invoice from './Invoice';
import Payment from './Payment';
import Price from './Price';
import Description from './Description';
import {mapActions} from 'vuex';
import {post} from '@/services/api';
import {
  InvoiceInformationIndex,
} from '@/services/endpoint.json';
import {decrypt} from "@/helpers/encrypt";


export default {
  name: 'CreditPrice',
  components: {
    Price,
    Invoice,
    Payment,
    Description
  },
  data: () => ({
    configSelected: null,
    configExtraData: null,
    company: null,
    urlFrom: null,
    redirectBack: false,
  }),
  mounted() {
    this.getData().then();
    if (this.$route.query.from !== undefined) {
      this.redirectBack = true
    }
  },
  methods: {
    ...mapActions('buySale', ['loadInformation']),

    async getData() {
      this.clear();
      const storeUser = localStorage.getItem('admin-id')
      const userId = decrypt(storeUser)
      const {data} = await post(InvoiceInformationIndex, {id: userId}, true);
      if (Number(data.statusCode) === 200) {
        this.configSelected = data.message.configuration;
        if (this.configSelected !== null) {
          this.configExtraData = this.configSelected.extraData
        }
        this.company = data.message.company;
      }
    },
    clear() {
      this.invoiceInformation = null;
      this.paymentInformation = null;
    },
    redirectTo(){
      this.urlFrom = this.$route.query.from
      this.$router.push(this.urlFrom)
    }
  }
};
