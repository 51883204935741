<template>
  <b-card class="no-shadow p-0">
    <div class="pt-2 dataTables_wrapper VueTables no-shadow table-responsive">
      <b-row class="p-3">
        <b-col lg="4" sm="12">
          <b-form-select :options="dsgs" v-model="filter.dsg">
            <b-form-select-option :value="null">Select DSG</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="4" sm="12">
          <b-form-select :options="country_options" v-model="filter.country_id">
            <b-form-select-option :value="null">Select country</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="4" sm="12">
          <b-button class="btn btn-danger btn-block" @click="deleteByCountry">Delete by country</b-button>
        </b-col>
      </b-row>
      <b-table small bordered hover class="dataTable" :items="table.tableData" :fields="fields">
        <template #cell(to_charge)="{item}">
          <b-checkbox class="mx-auto checkbox-center" :checked="item.to_charge" disabled/>
        </template>
        <template #cell(to_show)="{item}">
          <b-checkbox class="mx-auto checkbox-center" :checked="item.to_show" disabled/>
        </template>
        <template #cell(options)="{item}">
          <b-button size="sm" variant="danger" title="Delete" @click="deleteItem(item._id)">
            <i class="material-icons">delete</i>
          </b-button>
          <b-button size="sm" variant="dark" title="view" @click="$refs.modal.open(item, item.dsg)">
            <i class="material-icons">compare_arrows</i>
          </b-button>
        </template>
      </b-table>
    </div>
    <div class="d-flex justify-content-between">
      <p class="justify-content-start pl-4 pt-3">
        <span v-show="table.total">
          Showing {{ table.from }} to {{ table.to }} of
        {{ table.total }} records
        </span>
      </p>
      <b-pagination
        class="justify-content-end no-shadow p-2"
        :value="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
        @change="paginate"
      />
    </div>
    <modal-comparator ref="modal" url="vehicles-price-config/vehicle-tcu-to-compare/" :fields="fields_"/>
  </b-card>
</template>

<script>
import {Pagination} from "@/utils/pagination";
import {get, post} from "@/services/api";
import {serialize} from "@/utils";
import ModalComparator from "@/views/dashboard/vehicles-price-config/components/ModalComparator";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "TCUList",
  mixins:[show_alert_mixin],
  components: {ModalComparator},
  props: {
    country_options: {
      default: [],
      type: Array
    },
    dsgs: {
      default: [],
      type: Array
    }
  },
  data: () => ({
    fields: [
      'dsg',
      {key: 'stockPrice', label: 'Drivability'},
      {key: 'stageOnePrice', label: 'Stage I'},
      {key: 'stageTwoPrice', label: 'Stage II'},
      {key: 'stageTwoPlusPrice', label: 'Stage II+'},
      {key: 'stageThreePrice', label: 'Stage III'},
      {key: 'stageFourPrice', label: 'Stage IV'},
      {key: 'country.name', label: 'Country'},
      {key: 'distributor.username', label: 'Distributor'},
      {key: 'to_charge', label: 'To Charge'},
      {key: 'to_show', label: 'To Show'},
      'options'
    ],
    fields_: [
      'dsg',
      {key: 'stockPrice', label: 'Drivability'},
      {key: 'stageOnePrice', label: 'Stage I'},
      {key: 'stageTwoPrice', label: 'Stage II'},
      {key: 'stageTwoPlusPrice', label: 'Stage II+'},
      {key: 'stageThreePrice', label: 'Stage III'},
      {key: 'stageFourPrice', label: 'Stage IV'},
    ],
    table: new Pagination(),
    filter: {
      country_id: null,
      dsg: null
    }
  }),
  methods: {
    load() {
      this.$store.commit('loaderManager', true);
      get(`vehicles-price-config/tcu-list?page=${this.table.currentPage}&limit=${this.table.perPage}${serialize(this.filter, '&')}`)
        .then(({data}) => {
          this.table.total = data.count;
          this.table.setTableData(data.data);
        }).finally(() => this.$store.commit('loaderManager', false))
    },
    paginate(page) {
      this.table.currentPage = page;
      this.load();
    },
    deleteItem(_id) {
      this.$swal({
        title: 'Are you sure?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          post(`vehicles-price-config/tcu-delete/${_id}`, null, true)
            .then(() => {
              this.load();
              this.showAlertNotification('Deleted successfully')
            })
        }
      });
    },
    deleteByCountry() {
      const inputOptions = {}
      this.country_options.forEach(el => {
        inputOptions[el.value] = el.text;
      });
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'question',
        title: 'Select a country',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Confirm and delete",
        cancelButtonText: "Cancel",
        input: "select",
        inputOptions: inputOptions,
      }).then(async (value) => {
        if (value.isConfirmed) {
          this.deleteItem(value.value)
        }
      })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function () {
        this.load()
      }
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped>

</style>
