<template>
  <b-modal ref="modal" title="Edit Country distributor policy" v-if="model" @ok.prevent="save">
    <validation-observer ref="form">
      <validation-provider name="to charge" v-slot="{errors}" rules="required">
        <div class="form-group">
          <label>Minimum price</label>
          <b-form-input v-model.number="model.minimum_price"/>
          <span class="text-danger">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <div class="form-group">
        <label>Percentage "On request" and Edit price</label>
        <b-form-input type="range" min="1.00" max="1.99" step=".01" v-model="model.percentage_on_request"/>
        {{ ((( model.percentage_on_request /1 ) * 100) - 100).toFixed(0) }} %
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "EditPricePolicy",
  mixins: [show_alert_mixin],
  data() {
    return {
      model: null
    }
  },
  methods: {
    open(item) {
      this.model = item;
      this.$nextTick(() => {
        this.$refs.modal.show();
      })
    },
    send() {

    },
    save(modalEvent) {
      modalEvent.preventDefault();
      this.$refs.form.validate().then((success) => {
        if (success) {
          post(`vehicles-price-config/country-policies/${this.model._id}/update`, {
            percentage_on_request: this.model.percentage_on_request,
            minimum_price: this.model.minimum_price
          })
            .then(() => {
              this.$refs.modal.hide();
              this.$emit('close');
              this.showAlertNotification("Country distributor policy updated")
            })
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
