<template>
  <div class="p-0">
    <div class="p-3">
      <div class="text-right">
        <div class="btn-group">
          <b-button variant="primary" @click="downloadToConfig">Export excel</b-button>
          <router-link class="btn btn-primary" :to="{name: 'vehicles-importer'}" v-slot="{ navigate }">
            <span @click="navigate" @keypress.enter="navigate" role="link">Import excel</span>
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <b-tabs lazy>
        <b-tab lazy title="ECU Prices">
          <ECUList :country_options="country_options"/>
        </b-tab>
        <b-tab lazy title="TCU Prices">
          <TCUList :country_options="country_options" :dsgs="dsgs"/>
        </b-tab>
        <b-tab lazy title="Combo Prices">
          <CombosList :country_options="country_options" :dsgs="dsgs"/>
        </b-tab>
        <b-tab lazy title="Country Price policies">
          <PricePolicies/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {get} from "@/services/api";
import ECUList from "@/views/dashboard/vehicles-price-config/components/ECUList";
import TCUList from "@/views/dashboard/vehicles-price-config/components/TCUList";
import CombosList from "@/views/dashboard/vehicles-price-config/components/CombosList";
import PricePolicies from "@/views/dashboard/vehicles-price-config/components/PricePolicies";

export default {
  name: "Index",
  components: {PricePolicies, CombosList, TCUList, ECUList},
  data: () => ({
    country_options: [],
    dsgs: []
  }),
  methods: {
    downloadToConfig() {
      get(`vehicles-price-config/export`,
        null, true, 'arraybuffer')
        .then(({data}) => {
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.xlsx');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
    },
    getCountries() {
      get('geo/countries', null, true).then(({data}) => {
        this.country_options = data.map(row => ({
          value: row.id,
          text: row.name
        }))
      })
    },
    getDsgs() {
      get('vehicles-dsg', null, true).then(({data}) => {
        this.dsgs = data.message.map(row => ({
          value: row.DSG,
          text: row.DSG
        }))
      })
    },
  },
  mounted() {
    this.getCountries();
    this.getDsgs()
  }
}
</script>

<style>
.checkbox-center {
  width: fit-content;
}
</style>
