<template>
  <b-modal v-model="active" title="Showing differences between TVS and custom price country" size="lg" hide-footer>
    <b-row>
      <b-col>
        <b-list-group>
          <b-list-group-item v-for="field of fields" class="d-flex justify-content-between">
            <span>
              {{ (field.label ? field.label : field) }}
            </span>
            <span v-html="formatObjectCell(in_tvs[(field.key ? field.key : field)]) "></span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item v-for="field of fields" class="d-flex justify-content-between">
            <span>
              {{ (field.label ? field.label : field) }}
            </span>
            <span v-html="formatObjectCell(object_in[(field.key ? field.key : field)]) ">
            </span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {get} from "@/services/api";

export default {
  name: "ModalComparator",
  props: {
    url: String,
    fields: Array
  },
  data: () => ({
    in_tvs: {},
    object_in: {},
    active: false
  }),
  methods: {
    open(model, id) {
      this.object_in = model;
      this.load(id)
    },
    load(id) {
      get(`${this.url}${id}`).then(({data}) => {
        this.in_tvs = data;
        this.active = true;
      })
    },
    formatObjectCell(item) {
      if (item && typeof item === 'object') {
        const lines = []
        Object.keys(item).forEach(key => {
          if (typeof item[key] == 'object') {
            lines.push(`<div class="p-1 ${item[key].class}">${key} = ${item[key].value}</div>`)
          } else {
            lines.push(`<div class="p-1">${key} = ${item[key]}</div>`)
          }
        })
        return `<div>${lines.join('')}</div>`;
      } else {
        return `<div class="p-1">${item? item : 'X'}</div>`;
      }
    },
  }
}
</script>

<style scoped>

</style>
